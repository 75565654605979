import React from 'react';
import logo from '../../images/modenbo_logo.png';
import linkedin from '../../images/linkedin_icon.png';
import instagram from '../../images/instagram_icon.png';
import mail from '../../images/mail_icon.svg';
import call from '../../images/call_icon.svg';
import './footer.css';
import handleSendEmail from '../email/SendEmail';

const Footer = () => (
  <footer className="footer pt-5">
    <div className="footer-content">
      <div className="footer-logo">
        <a href="/">
          <img src={logo} alt="Modenbo Technologies Logo" />
        </a>
      </div>
      <div className="footer-links">
        <a href="/about">About Us</a>
        <a href="/contact">Contact Us</a>
        <a href="/privacy">Privacy Policy</a>
        <a href="/terms">Terms of Use</a>
      </div>
      <div className="footer-icons">
        <a
          href="https://www.linkedin.com/company/modenbo-technologies"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="Modenbo Technologies Linkedin" />
        </a>
        <a
          href="https://www.instagram.com/modenbotech"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="Modenbo Technologies Instagram" />
        </a>
      </div>
    </div>
    <div className="footer-contact">
      <button
        type="button"
        aria-label="Send email to modenbo"
        onClick={handleSendEmail}
        className="footer-mail"
      >
        <img src={mail} alt="Modenbo Technologies Email" />
        <p>contactus@modenbo.com</p>
      </button>
      <a href="/" className="footer-mail">
        <img src={call} alt="Modenbo Technologies Phone" />
        <p>+233 53 337 3736</p>
      </a>
    </div>
    <p className="footer-copyright">2023 © Copyright. Modenbo Technologies</p>
  </footer>
);

export default Footer;
