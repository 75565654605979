import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './navigation.css';
import logo from '../../images/modenbo_logo.png';

const Navigation = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    closeMobileMenu();
  };

  const handleNavLinkClick = () => {
    scrollToTop();
    closeMobileMenu();
  };

  return (
    <>
      <nav className="pt-[68px] pb-[34px] navbar">
        <div className="navbar-container mx-[96px]">
          <NavLink to="/" className="home-navbar-logo" onClick={handleNavLinkClick}>
            <img src={logo} alt="logo" className="home_logo" />
          </NavLink>
          <div
            className="menu-icon"
            onClick={handleClick}
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
          >
            {click ? <FaTimes /> : <FaBars />}
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
              <NavLink
                to="/portfolio"
                className={({ isActive }) => `nav-links${isActive ? ' activated' : ''}`}
                onClick={handleNavLinkClick}
              >
                Portfolio
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/about"
                className={({ isActive }) => `nav-links${isActive ? ' activated' : ''}`}
                onClick={handleNavLinkClick}
              >
                About Us
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                to="/foundation"
                className={({ isActive }) => `nav-links${isActive ? ' activated' : ''}`}
                onClick={handleNavLinkClick}
              >
                Foundation
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                to="/contact"
                className={({ isActive }) => `nav-links${isActive ? ' activated' : ''}`}
                onClick={handleNavLinkClick}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
