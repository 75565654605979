// Portfolio code goes here
import React from 'react';
import Navigation from '../navigation/Navigation';
import Footer from '../footer/Footer';
import portLeft from './assets/portfolio_left.png';
import portRight from './assets/portfolio_right.png';

const Portfolio = () => (
  <>
    <Navigation />
    <img src={portLeft} alt="" className=" w-[64px] h-[169px] lg:w-[164px] lg:h-[269px] max-w-full z-[-1] mt-[70px] ml-[-20px] absolute" />
    <div className=" mb-[260px] w-full px-2 sm:px-[12] md:px-[22px] lg:px-[32px] xl:px-[96px] flex flex-col justify-center items-center">
      <div className=" mt-[55px] sm:mt-[65px] md:mt-[75px] lg:mt-[85px] xl:mt-[113px] text-center">
        <h1 className=" font-bold text-[30px] sm:text-[50px] leading-normal">
          Portfolio
        </h1>
        {/** Projects */}
        <div className=" w-full flex  flex-wrap gap-[16px] sm:gap-[26px] md:gap-[46px] lg:gap-[66px] xl:gap-[8.75rem] justify-center items-center">
          {/** Project */}
          <div className=" w-[240px] sm:w-[340px] md:w-[380px] lg:w-[400px] xl:w-[440px] mt-[32px] sm:mt-[40px] md:mt-[50px] lg:mt-[60px] xl:mt-[70px] flex flex-col text-left">
            <div className=" px-3 md:px-0 w-full h-[200px] sm:h-[300px] xl:h-[340px] rounded-[20px] bg-goodifly flex justify-center items-center bg-center bg-cover" />
            <h1 className=" mt-6 text-[20px] sm:text-[25px] leading-[30.33px] font-bold">Goodifly</h1>
            <h1 className=" text-[#707070] text-[16px] sm:text-[18px] leading-[21.83px] mt-2">
              Think of it as the &apos;Uber&apos; or &apos;Airbnb&apos; of flight travels.
              Goodifly redefines air freight, allowing seamless
              deliveries across borders by connecting users with
              travelers and leveraging shared flight space.
            </h1>
            <a href="https://www.goodifly.com" target="_blank" rel="noreferrer" className=" mt-7 cursor-pointer w-[180px] h-[60px] rounded-[50px] flex items-center justify-center text-center bg-[#4248E2]">
              <h1 className=" text-[#FFFFFF] text-[16px] lg:text-[18px] leading-[21.83px]">View project</h1>
            </a>
          </div>

          {/** Project */}
          <div className=" w-[240px] sm:w-[340px] md:w-[380px] lg:w-[400px] xl:w-[440px] mt-[32px] sm:mt-[40px] md:mt-[50px] lg:mt-[60px] xl:mt-[70px] flex flex-col text-left">
            <div className=" px-3 md:px-0 w-full h-[200px] sm:h-[300px] xl:h-[340px] rounded-[20px] bg-rateAvender flex justify-center items-center bg-center bg-cover" />
            <h1 className=" mt-6 text-[20px] sm:text-[25px] leading-[30.33px] font-bold">Rate A Vendor</h1>
            <h1 className=" text-[#707070] text-[16px] sm:text-[18px] leading-[21.83px] mt-2">
              Rate A Vendor empowers users to curate
              exceptional events through listing, reviewing, and
              rating vendors. We&apos;re enhancing event planning by
              offering a platform for vendor listing and reviews
            </h1>
            <a href="https://www.rateavendor.com" target="_blank" rel="noreferrer" className=" mt-7 cursor-pointer w-[180px] h-[60px] rounded-[50px] flex items-center justify-center text-center bg-[#4248E2]">
              <h1 className=" text-[#FFFFFF] text-[16px] lg:text-[18px] leading-[21.83px]">View project</h1>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-row-reverse z-[-1] relative">
      <img src={portRight} alt="" className=" -mt-[18rem] -mr-[20px] w-[64px] h-[169px] lg:w-[164px] lg:h-[269px] max-w-full float-right z-[-1] absolute" />
    </div>
    <Footer />
  </>
);

export default Portfolio;
