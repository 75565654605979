// About us code goes here
import React from 'react';
import { Helmet } from 'react-helmet';
import Navigation from '../navigation/Navigation';
import Footer from '../footer/Footer';
import emmanuel from '../../images/emmanuel.png';
import yaw from '../../images/Yaw.jpg';
import crownie from '../../images/Crownie.jpg';
import joseph from '../../images/Joseph.jpg';
import abednego from '../../images/abednego.jpg';
import abigail from '../../images/Abigail.jpg';
import aboutLeft from './assets/aboutLeft.png';
import aboutRight from './assets/aboutRight.png';

const team = [
  { name: 'Yaw Takyi', position: 'Founder & CEO', image: yaw },
  { name: 'Crownie Eme', position: 'Cofounder & CTO', image: crownie },
  { name: 'Abigail Adoyi', position: 'Project Manager', image: abigail },
  { name: 'Joseph Kwentsir', position: 'Snr. Developer', image: joseph },
  { name: 'John Abednego J.', position: 'Jnr. Developer', image: abednego },
  { name: 'Emmanuel G. Asante', position: 'Jnr. UI/UX Designer', image: emmanuel },
];
const About = () => (
  <>
    <Helmet>
      <meta property="og:title" content="About Us" />
      <meta property="og:description" content={"We are engineering the future with technology by reshaping industries uplifting communities and pushing the boundaries of what's achievable"} />
      <meta property="og:url" content="https://www.modenbo.com/about" />
      <meta property="og:type" content="website" />
    </Helmet>
    <Navigation />
    <img src={aboutLeft} alt="" className=" w-[64px] h-[169px] lg:w-[164px] lg:h-[269px] max-w-full z-[-1] mt-[320px] ml-[-20px] absolute" />
    <div className=" w-full px-[32px] sm:px-[122px] flex flex-col justify-center items-center">
      <div className=" mt-[65px] sm:mt-[120px] text-center">
        <h1 className=" font-bold text-[30px] sm:text-[50px] leading-normal">
          The Modenbo Story
        </h1>
        <h1 className=" text-[#707070] mt-[23px] sm:mt-10 text-[16px] sm:text-[18px]">
          Our journey started with a shared vision – a
          vision to drive innovation and elevate technology&apos;s
          role in society. As a passionate team of trailblazers,
          Modenbo was born, and we&apos;ve ceaselessly pursued the extraordinary,
          fuelled by the belief that every hurdle is an opportunity and every
          challenge is a chance to reshape the future.
        </h1>
        <h1 className=" mt-[111px] sm:mt-[122px] font-bold text-[30px] sm:text-[50px] leading-normal mb-[34px] sm:mb-[109px]">
          Team Members
        </h1>
        <div className=" w-full flex flex-wrap gap-[34px] sm:gap-[80px] mb-[185px] sm:mb-[245px] justify-center">
          {team.map((person) => (
            <div key={person} className=" flex flex-col">
              <div className=" w-[250px] sm:w-[345px] h-[250px] sm:h-[345px] bg-[#D9D9D9] rounded-[250px] sm:rounded-[345px]">
                <img src={person.image} alt="" className="w-[250px] sm:w-[345px] h-[250px] sm:h-[345px] rounded-[250px] sm:rounded-[345px] object-cover" />
              </div>
              <h1 className=" text-[16px] sm:text-[18px] mt-5">{person.name}</h1>
              <h1 className="text-[16px] sm:text-[18px] text-[#707070] ">{person.position}</h1>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="flex flex-row-reverse z-[-1] relative">
      <img src={aboutRight} alt="" className=" -mt-[35rem] -mr-[20px] w-[64px] h-[169px] lg:w-[164px] lg:h-[269px] max-w-full float-right z-[-1] absolute" />
    </div>
    <Footer />
  </>
);

export default About;
