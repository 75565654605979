import React from 'react';
import Navigation from '../navigation/Navigation';
import Footer from '../footer/Footer';
import privacyLeft from './assets/privacy_left.png';
import privacyRight from './assets/privacy_right.png';

const Privacy = () => (
  <div className=" overflow-x-hidden">
    <Navigation />
    <img src={privacyLeft} alt="" className=" w-[64px] h-[169px] lg:w-[164px] lg:h-[269px] max-w-full z-[-1] mt-[664px] ml-[-18px] absolute" />
    <div className=" mb-[260px] w-full px-[40px] md:px-[96px] xl:px-[122px] flex flex-col justify-center items-center">
      <div className=" text-[#707070] mt-[60px] sm:mt-[65px] md:mt-[75px] lg:mt-[85px] xl:mt-[99px] text-left">
        <h1 className=" text-[#000000] font-bold text-[30px] sm:text-[50px] leading-normal text-center">
          Privacy Policy
        </h1>
        <p className=" mt-[42px] mb-6">This Privacy Policy was last updated on 25th August, 2023</p>

        <p>
          This privacy notice for Modenbo Technologies
          (&apos;we&apos;, &apos;us&apos;, or &apos;our),
          describes how and why we might collect, store, use,
          and/or share (&apos;process’) your
          information when you use our services
          (&apos;Services&apos;), such as when you:
        </p>
        <ul className=" ml-5 list-disc mb-6">
          <li>
            Visit our website at
            {' '}
            <a href="https://www.modenbo.com">https://www.modenbo.com</a>
            ,
            or any website of ours that links. to this privacy notice
          </li>
          <li>
            Engage with us in-other related ways,
            including any sales, marketing, or events
          </li>
        </ul>

        <p className=" mb-6">
          Questions or concerns? Reading this privacy
          notice will help you understand your privacy rights
          and choices. If you do not agree with our policies and
          practices, please do not use our Services. If you still
          have any questions or concerns, please contact us at
          {' '}
          <a href="/contact">contactus@modenbo.com</a>
          .
        </p>

        <p className=" mb-6">
          1.
          {' '}
          <span className=" ml-1 font-bold text-[18px] text-[#000000]">WHAT INFORMATION DO WE COLLECT?</span>
        </p>

        <p>
          Personal information you disclose to us In Short: We collect personal
          information that you provide to us.
        </p>
        <ul className=" ml-5 list-disc mb-6">
          <li>
            We collect personal information that you voluntarily provide to us
            when you express an interest in obtaining information about us or our products and
          </li>
          <li>
            Services, when you participate in activities on the Services, or
            otherwise when you contact us.
          </li>
        </ul>

        <p>
          Personal Information Provided by You. The personal
          information that we collect depends on the
          context of your interactions with us and the Services,
          the choices you make, and the products and features you use.
          The personal information we collect may include the following names:
        </p>
        <ul className=" ml-5 list-disc mb-6">
          <li>email</li>
          <li>addresses</li>
        </ul>

        <p>Sensitive Information. We do not process sensitive information</p>
        <p className=" mb-6">
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any
          changes to such personal information.
        </p>

        <p>Information automatically collected</p>
        <p className=" mb-6">
          In Short: Some information - such as your
          Internet Protocol (IP) address. and/or browser and device
          characteristics - is collected automatically when you
          visit our Services.
        </p>

        <p className=" mb-6">
          We automatically collect certain information when you visit,
          use, or navigate the Services. This information does not reveal
          your specific identity (like your name or contact information)
          but may include device and usage information, such as your IP
          address, browser and device characteristics, operating
          system, language preferences, referring URLs, device name,
          country, location, information about how and when you use our
          Services, and other technical
          information. This information is primarily needed to maintain
          the security and Services, and other technical information.
          This information is primarily
          needed to maintain the security and operation of our Services,
          and for our internal analytics and reporting purposes.
        </p>

        <p className=" mb-6">The information we collect includes:</p>

        <p className=" mb-6">
          Log and Usage Data. Log and usage data is service-related, diagnostic,
          usage, and performance information our servers automatically collect when you
          access or use our Services and which we record in log files.
          Depending on how you interact with us, this log data may include
          your IP address, device information, browser type, and settings
          and information about your activity in the Services (such as the
          date/time stamps associated with your usage, pages and files viewed,
          searches, and other actions you take such as which features you use),
          device event information (such as system activity, error
          reports (sometimes called &apos;crash dumps’), and hardware settings).
        </p>

        <p className=" mb-6">
          2.
          <span className=" ml-1 font-bold text-[18px] text-[#000000]">HOW DO WE PROCESS YOUR INFORMATION?</span>
        </p>

        <p className=" mb-6">
          In Short: We process your information to provide, improve,
          and administer our Services, communicate with you, for security and
          fraud prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </p>

        <p>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </p>
        <p className=" mb-6">
          {' '}
          To respond to user inquiries/offer support to users. We may
          process your information to respond to your inquiries and
          solve any potential issues you
          might have with the requested service
        </p>

        <p className=" mb-6">
          3.
          <span className=" ml-1 font-bold text-[18px] text-[#000000]">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span>
        </p>

        <p>
          In Short: We may share information in specific situations
          described in this section and/or with the following third parties.
          We may need to share your
          personal information in the following situations:
        </p>
        <p className=" mb-6">
          Business Transfers. We may share or transfer
          your information in connection with, or during negotiations of,
          any merger, sale of company assets,
          financing, or acquisition of all or a portion of our business
          to another company.
        </p>

        <p className=" mb-6">
          4.
          <span className=" ml-1 font-bold text-[18px] text-[#000000]">HOW LONG DO WE KEEP YOUR INFORMATION?</span>
        </p>

        <p>
          In Short: We keep your information for as long as necessary
          to fulfil the purposes outlined in this privacy notice unless
          otherwise required by law.
        </p>
        <p className=" mb-6">
          We will only keep your personal information
          for as long as it is necessary for the purposes set out in this privacy
          notice, unless a longer retention period is
          required or permitted by law (such as tax, accounting, or other
          legal requirements).
        </p>

        <p className=" mb-6">
          When we have no ongoing legitimate business
          need to process your personal information, we will either delete or
          anonymise such information, or, if this is
          not possible (for example, because your personal information has been
          stored in backup archives), then we will securely store your personal information
          and isolate it from any further processing until deletion is possible.
        </p>

        <p className=" mb-6">
          5.
          <span className=" ml-1 font-bold text-[18px] text-[#000000]">HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
        </p>

        <p className=" mb-6">
          In Short: We aim to protect your personal information
          through a system of organisational and
          technical Security measures.
        </p>

        <p className=" mb-6">
          We have implemented appropriate and reasonable
          technical and organisational security measures designed to protect
          the security of any personal information we process. However, despite
          our safeguards and efforts to secure your information, no electronic
          transmission over the Internet or information storage technology can
          be guaranteed to be 100% secure, so we cannot promise or guarantee that
          hackers, cybercriminals, or other unauthorised third parties will not
          be able to defeat our security and improperly collect, access, steal,
          or modify your information. Although we will do our
          best to protect your personal information, transmission of
          personal information to and from our Services is at your own risk.
          You should only access the
          Services within a secure environment.
        </p>

        <p className=" mb-6">
          6.
          <span className=" ml-1 font-bold text-[18px] text-[#000000]">WHAT ARE YOUR PRIVACY RIGHTS?</span>
        </p>

        <p>In Short: You may review, change, or terminate your account at any time.</p>
        <p className=" mb-6">
          If you are located in the EA or UK and you believe we
          are unlawfully processing your personal information, you also have the right
          to complain to your
          Member State data protection authority or UK data protection authority.
        </p>

        <p>
          If you are located in Switzerland, you may contact the Federal Data
          Protection and Information Commissioner.
        </p>
        <p className=" mb-6">
          Withdrawing your consent: If we are relying on your
          consent to process your personal information, which may be express and/or
          implied consent depending on the applicable law, you have the right
          to withdraw your consent at any time. You can withdraw your consent
          at any time by contacting us by using the contact details provided
          in the section &apos;HOW CAN YOU CONTACT. US ABOUT THIS NOTICE?&apos; below.
        </p>

        <p className=" mb-6">
          However, please note that this will not affect
          the lawfulness of the processing before its withdrawal nor, when applicable
          law allows, will it affect the processing of your personal information
          conducted in reliance on lawful processing grounds other than consent.
        </p>

        <p className=" mb-6">
          If you have questions or comments about your
          privacy rights, you may email us at
          {' '}
          <a href="mailto:contactus@modenbo.com">contactus@modenbo.com</a>
          .
        </p>

        <p className=" mb-6">
          7.
          <span className=" ml-1 font-bold text-[18px] text-[#000000]">CONTROLS FOR DO-NOT-TRACK FEATURES</span>
        </p>

        <p className=" mb-6">
          {' '}
          Most web browsers and some mobile operating systems
          and mobile applications include a Do-Not-Track (&apos;DNT’)
          feature or setting you can activate to
          signal your privacy preference not to have data about
          your online browsing activities monitored and collected.
          At this stage no uniform technology
          standard for recognising and implementing
          DNT signals has been finalised. As such,
          we do not currently respond to DNT browser
          signals or any other mechanism that automatically
          communicates your choice not to be tracked online.
          If a standard for online tracking is adopted that we must follow in the
          future, we will inform you about that practice in a
          revised version of this privacy notice.
        </p>

        <p className=" mb-6">
          8.
          <span className=" ml-1 font-bold text-[18px] text-[#000000]">DO WE MAKE UPDATES TO THIS NOTICE?</span>
        </p>

        <p>
          In Short: Yes, we will update this notice as necessary
          to stay compliant with relevant laws.
        </p>
        <p className=" mb-6">
          We may update this privacy notice from time to time.
          The updated version will be indicated by an updated &apos;Revised&apos;
          date and the updated version will be
          effective as soon as it is accessible. If we make material changes to
          this privacy notice, we may notify you either by prominently
          posting a notice of such
          changes or by directly sending you a notification.
          We encourage you to review this privacy notice frequently to
          be informed of how we are protecting your
          information.
        </p>

        <p className=" mb-6">
          9.
          <span className=" ml-1 font-bold text-[18px] text-[#000000]">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
        </p>

        <p className=" mb-6">
          If you have questions or comments about this notice,
          you may email us at
          {' '}
          <a href="mailto:contactus@modenbo.com">contactus@modenbo.com</a>
          .
        </p>

        <p className=" mb-6">
          10.
          <span className=" ml-1 font-bold text-[18px] text-[#000000]">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span>
        </p>

        <p className=" mb-6">
          You have the right to request access to the personal
          information we collect from you, change that information,
          or delete it. To request to review, update,
          or delete your personal information, please
          email us at
          {' '}
          <a href="mailto:contactus@modenbo.com">contactus@modenbo.com</a>
          .
        </p>
      </div>
    </div>
    <div className="flex flex-row-reverse z-[-1] relative">
      <img src={privacyRight} alt="" className=" -mt-[202rem] w-[64px] h-[169px] lg:w-[164px] lg:h-[210px] max-w-full float-right z-[-1] absolute" />
    </div>
    <Footer />
  </div>
);

export default Privacy;
