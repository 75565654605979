import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';
import Navigation from '../navigation/Navigation';
import Footer from '../footer/Footer';
import mailIcon from '../../images/mail_icon.svg';
import callIcon from '../../images/call_icon.svg';
import linkedin from '../../images/linkedin_icon.png';
import instagram from '../../images/instagram_icon.png';
import contactLeft from './assets/contactLeft.png';
import contactRight from './assets/contactRight.png';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Accessing form values using the form reference
    const userName = form.current.user_name.value;
    const userEmail = form.current.user_email.value;
    const message = form.current.message.value;

    // Clear the form fields if needed
    emailjs.send('service_zevrh2u', 'template_unlhswq', {
      from_name: userName,
      message,
      reply_to: userEmail,
      from_email: userEmail,
    }, '5fu6V6xbuhoS5wx0a').then((result) => {
      if (result.text === 'OK') {
        // eslint-disable-next-line no-alert
        alert('Thanks for Contacting Us');
        window.location.reload();
      }
    }, (error) => {
      console.log(error.text); // eslint-disable-line no-console
    });
    form.current.reset();
  };
  return (
    <>
      <Helmet>
        <meta property="og:title" content="Contact Us" />
        <meta property="og:description" content={"We are engineering the future with technology by reshaping industries uplifting communities and pushing the boundaries of what's achievable"} />
        <meta property="og:url" content="https://www.modenbo.com/contact" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Navigation />
      <img src={contactLeft} alt="" className=" w-[64px] h-[169px] lg:w-[164px] lg:h-[269px] max-w-full z-[-1] mt-[610px] ml-[-18px] absolute" />
      <div className=" mb-[260px] w-full px-2 sm:px-[12] md:px-[22px] lg:px-[32px] xl:px-[122px] flex flex-col justify-center items-center">
        <div className=" mt-[60px] sm:mt-[65px] md:mt-[75px] lg:mt-[85px] xl:mt-[99px] text-center">
          <h1 className=" font-bold text-[30px] sm:text-[50px] leading-normal">
            Contact Us
          </h1>
          <div className=" mt-[76px] w-full flex  flex-wrap lg:flex-nowrap gap-[16px] sm:gap-[26px] md:gap-[46px] lg:gap-[66px] xl:gap-[153px] justify-center">
            <div className=" text-center items-center md:items-start md:text-left flex flex-col gap-5">
              <h1 className="font-bold text-[30px] sm:text-[50px] leading-normal">
                Send Us A Message:
              </h1>
              <p className=" text-[18px]">
                Have a specific question or request?
                Fill out the form below, and we&apos;ll get back to you as soon as possible.
              </p>
              <a href="mailto:contactus@modenbo.com" className="flex gap-5">
                <img src={mailIcon} alt="" />
                <p>contactus@modenbo.com</p>
              </a>
              <a href="tel:+233533373736" className="flex gap-5">
                <img src={callIcon} alt="" />
                <p>+233 53 337 3736</p>
              </a>
              <div className="flex footer-icons">
                <img src={linkedin} alt="" />
                <img src={instagram} alt="" />
              </div>
            </div>

            <form ref={form} onSubmit={sendEmail} className=" bg-[#E5E5E5] p-4 sm:p-[45px] rounded-[30px] flex flex-col gap-[22px] w-full  sm:w-[480px] md:w-[550px]">
              <input required type="text" name="user_name" placeholder="name" className="  w-full sm:w-[340px] md:w-[466px] sm:placeholder-black text-[18px] leading-[21.83px] px-12  h-[70px] rounded-[60px] border-2 border-solid " />
              <input required type="email" name="user_email" placeholder="email" className="  w-full sm:w-[340px] md:w-[466px] sm:placeholder-black text-[18px] leading-[21.83px] px-12  h-[70px] rounded-[60px] border-2 border-solid " />
              <textarea required name="message" id="message" placeholder="message" cols="30" rows="10" className=" w-full sm:w-[340px] md:w-[466px] sm:placeholder-black text-[18px] leading-[21.83px] px-12 py-6 resize-none rounded-[30px]  border-2 border-solid " />
              <button type="submit" className="bg-[#4248E2] ml-[2px] h-[70px] rounded-[50px] text-white text-[18px] leading-[21.83px]">Submit</button>
            </form>

          </div>
        </div>
      </div>
      <div className="flex flex-row-reverse z-[-1] relative">
        <img src={contactRight} alt="" className=" -mt-[65rem] -mr-[20px] w-[64px] h-[169px] lg:w-[164px] lg:h-[269px] max-w-full float-right z-[-1] absolute" />
      </div>
      <Footer />
    </>
  );
};

export default Contact;
