import React from 'react';
import { Helmet } from 'react-helmet';
// import { FaArrowRight } from 'react-icons/fa';
import { BsArrowRightShort } from 'react-icons/bs';
import HomeNavigation from '../navigation/homeNavigation';
import Footer from '../footer/Footer';
import './home.css';
import homeleft from './assets/home_left.png';
import homeright from './assets/home_right.png';
// import handleSendEmail from '../email/SendEmail';

const Home = () => (
  <>
    <Helmet>
      <title>Modenbo</title>
      <subtitle>
        Modenbo Technologies - Innovate, Transform, Excel: The Modenbo Way
      </subtitle>
      <meta
        name="description"
        content="We are engineering the future with technology by reshaping industries uplifting communities and pushing the boundaries of what’s achievable"
      />
      <meta name="robots" content="index, follow" />
      <meta
        name="keywords"
        content="Modenbo, Modenbo Technologies, Modenbo Technologies Limited, Modenbo Technologies Limite, Modenbo Technologies Ltd"
      />
      <meta property="og:title" content="Modenbo" />
      <meta
        property="og:description"
        content={
          "We are engineering the future with technology by reshaping industries uplifting communities and pushing the boundaries of what's achievable"
        }
      />
      <meta property="og:url" content="https://www.modenbo.com" />
      <meta property="og:type" content="website" />
    </Helmet>
    <div>
      <HomeNavigation />
      <div className="  w-full bg-smallMain bg-no-repeat sm:bg-main bg-cover bg-center xl:bg-bottom h-auto absolute top-0">
        <div className=" mt-[120px] sm:mt-[100px] md:mt-[110px] lg:mt-[128px] xl:mt-[180px] home-container px-[40px] !important md:px-[96px] !important text-white flex-container">
          <div>
            <h1 className=" text-[30px] sm:text-[50px] font-bold">
              Innovate, Transform, Excel: The Modenbo Way
            </h1>
            <p className="mt-[17px] text-[16px] sm:text-[18px]">
              We are engineering the future with technology by reshaping
              industries uplifting communities and pushing the boundaries of
              what’s achievable
            </p>
            <div className="lets-connect items-center mt-[17px]">
              <p className=" text-[16px] sm:text-[18px]">Let&apos;s connect</p>
              <a
                href="/contact"
                className=" ml-[18px] "
                aria-roledescription="Link to contact us page"
              >
                <BsArrowRightShort className="arrow-icon w-[43px] h-[43px]" />
              </a>
            </div>
          </div>
          <div className=" hidden xl:block w-[75%]" />
        </div>
      </div>
      <div className=" smallOthers mt-[25rem] sm:mt-[28rem] md:mt-[28rem] lg:mt-[30rem] pt-[191px]">
        <img
          src={homeleft}
          alt=""
          className=" w-[64px] h-[169px] lg:w-[164px] lg:h-[269px] max-w-full  z-50 absolute"
        />
        <div className=" home-container px-[40px] !important md:px-[96px] !important home-about-container ">
          <h2>About Us</h2>
          <p className="text-[18px]  md:w-[700px] lg:w-[941px]">
            At Modenbo, our mission is to be the driving force behind positive
            transformation through technology. Rooted in dedication, we are
            committed to reshaping traditional paradigms by crafting
            groundbreaking solutions. Our innovative products empower businesses
            and individuals to thrive in the digital era and enable us to create
            a resounding global impact across borders and industries
          </p>
          <a
            href="/about"
            className="learn-more text-[18px] leading-[21.83px"
            aria-roledescription="Link to about us page"
          >
            Learn More
          </a>
        </div>
        <div className="flex flex-row-reverse">
          <img
            src={homeright}
            alt=""
            className=" w-[64px] h-[169px] lg:w-[164px] lg:h-[269px] max-w-full float-right z-50 absolute"
          />
        </div>
        <div className="home-container px-[40px] !important md:px-[96px] !important portfolio-container">
          <h2>Our Portfolio</h2>
          <p
            className=" md:w-[700px] lg:w-[941px] text-[18px]"
            aria-roledescription="our portfolio"
          >
            Fueled by a dynamic team of seasoned experts, Modenbo stands at the
            forefront of crafting versatile B2C and enterprise solutions. Our
            wealth of collective experience spans diverse fields, fueling
            unparalleled innovation. We&apos;re dedicated to delivering
            solutions that push boundaries, reshaping industries and propelling
            businesses to new heights. Our journey is fueled by a shared
            commitment to excellence and a deep grasp of the ever-evolving tech
            landscape
          </p>
          <a
            href="/portfolio"
            className="learn-more text-[18px] leading-[21.83px"
            aria-roledescription="link to portfolio"
          >
            Learn More
          </a>
        </div>
      </div>

      {/* <div className="email-container">
        <h3>Get in Touch</h3>
        <div className="send-email">
          <p>info@modenbo.com</p>
          <button
            type="button"
            aria-label="Send email to modenbo"
            onClick={handleSendEmail}
          >
            <FaArrowRight className="arrow-icon" />
          </button>
        </div>
      </div> */}
    </div>
    <Footer />
  </>
);

export default Home;
