import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './homeNavigation.css';
import homelogo from '../../images/home_logo.png';

const HomeNavigation = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    closeMobileMenu();
  };

  const handleNavLinkClick = () => {
    scrollToTop();
    closeMobileMenu();
  };

  return (
    <>
      <nav className=" pt-[68px] pb-[34px] home-navbar bg-homeNav bg-cover bg-center">
        <div className="home-navbar-container home_container mx-[96px]">
          <NavLink to="/" className="home-navbar-logo" onClick={closeMobileMenu}>
            <img src={homelogo} alt="logo" className="home_logo" />
          </NavLink>
          <div
            className="menu-icon"
            onClick={handleClick}
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
            aria-roledescription="Menu icon to access navigation links"
          >
            {click ? <FaTimes color="white" /> : <FaBars color="white" />}
          </div>
          <ul className={click ? 'home-nav-menu active' : 'home-nav-menu'}>
            <li className="home-nav-item">
              <NavLink
                to="/portfolio"
                className={({ isActive }) => `home-nav-links${isActive ? ' activated' : ''}`}
                onClick={handleNavLinkClick}
                aria-roledescription="Portfolio link to access portfolio page"
              >
                Portfolio
              </NavLink>
            </li>
            <li className="home-nav-item">
              <NavLink
                to="/about"
                className={({ isActive }) => `home-nav-links${isActive ? ' activated' : ''}`}
                onClick={handleNavLinkClick}
                aria-roledescription="About link to access about page"
              >
                About Us
              </NavLink>
            </li>
            {/* <li className="home-nav-item">
              <NavLink
                to="/foundation"
                className={({ isActive }) => `home-nav-links${isActive ? ' activated' : ''}`}
                onClick={handleNavLinkClick}
                aria-roledescription='Foundation link to access foundation page'
              >
                Foundation
              </NavLink>
            </li> */}
            <li className="home-nav-item">
              <NavLink
                to="/contact"
                className={({ isActive }) => `home-nav-links${isActive ? ' activated' : ''}`}
                onClick={handleNavLinkClick}
                aria-roledescription="Contact link to access contact page"
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default HomeNavigation;
